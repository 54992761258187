
import "./App.scss";
const dataFooter = {
  title: "Kết nối với chúng tôi",
  phone: "0976 389 199",
  email: "Info@omfarm.org",
  linkFacebook: "https://www.facebook.com/omfarm.9299",
  linkYoutube: "https://www.youtube.com/@Ommanivn",
  linkTiktok: "https://www.facebook.com/omfarm.9299",
  image: "/images/2023-05-25/fd0732f8-12c5-4931-987c-c7a3ab72b741_logo-omfarm.png",
  linkInfo: [
    {
      title: "Dịch vụ nổi bật",
      items: [
        {
          name: "GAP",
          target: "/gap?tab=tieu-chuan"
        },
        {
          name: "Nông nghiệp hữu cơ",
          target: "/nong-nghiep-huu-co?tab=tieu-chuan"
        },
        {
          name: "Tư vấn",
          target: "/gap?tab=tu-van"
        },
        {
          name: "Chuyển đổi số",
          target: "/partner"
        },
        {
          name: "Shop",
          target: "/store"
        },
        {
          name: "Kiểm nghiệm",
          target: "/partner"
        },
        {
          name: "Nghiên cứu phát triển",
          target: "/partner"
        },
        {
          name: "Lĩnh vực",
          target: "/partner"
        }
      ]
    },
    {
      title: "Ommani",
      items: [
        {
          name: "Đối tác & Chứng nhận",
          target: "/partner"
        },
        {
          name: "Tin tức & Sự kiện",
          target: "/tin-tuc"
        }
      ]
    },
    {
      title: "Chính sách",
      items: [
        {
          name: "Quy định chung",
          target: "/quy-dinh-chung"
        },
        {
          name: "Chính sách bảo mật",
          target: "/chinh-sach-bao-mat"
        },
        {
          name: "Hỗ trợ kỹ thuật",
          target: "/ho-tro-ky-thuat"
        },
        {
          name: "Giải quyết khiếu nại",
          target: "/khieu-nai"
        }
      ]
    },
    {
      title: "Hỗ trợ khách hàng",
      items: [
        {
          name: "Liên hệ",
          target: "/lien-he"
        },
        {
          name: "Góp ý & Khiếu nại",
          target: "/gop-y-khieu-nai"
        },
        {
          name: "Tài liệu hướng dẫn sử dụng",
          target: "/tai-lieu-huong-dan-su-dung"
        },
        {
          name: "Quy chế hoạt động",
          target: "/quy-trinh-lam-viec"
        }
      ]
    }
  ]
}

function App() {
  return(
      <div className="login_main">
        <div className="box_1">
          <div className="container">
            <div className="row flex-grow">
              <div className="col-12 col-md-6 d-flex align-items-center">
                <div className="w-100">
                  <h2>OMCheck</h2>
                  <h4>Nền tảng truy xuất Nông nghiệp, Thực phẩm thông minh!</h4>
                  <p>Phần mềm OMCheck là chìa khóa để theo dõi, quản lý và tối đa hóa năng suất và doanh thu cây trồng, vật nuôi bằng cách dự báo quy mô sản lượng dự kiến, cảnh báo sớm sâu bệnh, dự đoán thiên tai, lên kế hoạch gieo trồng, thu hoạch, truy xuất nguồn gốc…</p>
                  <div className="d-flex align-items-center justify-content-between login_main__btn--redirect">
                    <a className="mr-3 bt-login__link" target='_blank' href="https://farm.omcheck.com">
                      <button type="button"
                              className="btn bt-login d-flex align-items-center justify-content-center">
                        <img src="/assets/image/btn_omfarm.png" alt="omfarm"/>
                        <span className='bt-login__sub'>Đăng nhập {`>>`}</span>
                      </button>
                    </a>
                    <a className="mr-4 bt-login__link" target='_blank' href="https://food.omcheck.com">
                      <button type="button"
                              className="btn bt-login d-flex align-items-center justify-content-center">
                        <img src="/assets/image/btn_omfood.png" alt="omfarm"/>
                        <span className='bt-login__sub'>Đăng nhập {`>>`}</span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="d-none col-12 text-center col-md-6 d-lg-block">
                <img src="/assets/image/image1.png" alt=""/>
              </div>
            </div>
          </div>
        </div>
        <div className="box_2" style={{backgroundImage: `url("/assets/images/login/background.png")`}}>
          <div className="container h-100">
            <div className="row flex-grow h-100">
              <div className="col-12 col-md-6 d-none d-lg-flex align-items-center justify-content-end">
                <img src="/assets/image/image2.png" alt=""/>
              </div>
              <div className="col-12 col-md-6 pl-5 d-flex align-items-center">
                <div className="pl-5">
                  <h2>Chức năng của <br/> OMCheck</h2>
                  <ul>
                    <li>Quản lý thông tin dữ liệu nông dược, phân bón,<br/> nguyên vật liệu</li>
                    <li>Quản lý quy trình sản xuất sản phẩm.</li>
                    <li>Lập lô sản xuất và theo dõi sản xuất.</li>
                    <li>Nhật ký điện tử</li>
                    <li>Mã truy xuất nguồn gốc (QR)</li>
                    <li>Theo dõi vùng trồng trực quan</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box_3">
          <div className="container h-100">
            <div className="row flex-grow h-100">
              <div className="col-12 col-md-6 pr-5 d-flex align-items-center justify-content-end">
                <div className="pr-4">
                  <h2>Giá trị phần mềm<br/> OMCheck mang lại</h2>
                  <ul>
                    <li>Lập kế hoạch sản xuất và chăm sóc cây trồng hợp lý</li>
                    <li>Quản lý lao động</li>
                    <li>Ghi chép nhật ký sản xuất cây trồng</li>
                    <li>Quản lý tình hình sử dụng thuốc bảo vệ thực vật và phân bón</li>
                    <li>Truy xuất nguồn gốc.</li>
                    <li>Cảnh báo sớm sâu bệnh.</li>
                    <li>Cảnh báo thiên tai.</li>
                    <li>Tính toán lịch gieo trồng và đề xuất thời điểm thu hoạch hợp lý</li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-lg-start pl-5">
                <img src="/assets/image/image3.png" alt=""/>
              </div>
            </div>
          </div>
        </div>
        <div className="box_5">
          <img className="w-100" style={{height: 776}} src="/assets/image/banner_4.webp" alt=""/>
        </div>
        <div className="footer_om bg-white">
          <div className="footer_content">
            <div className="container d-flex justify-content-between flex-wrap">
              <div className="content_left">
                <div className="logo">
                  <img src="/assets/image/logo_footer.svg" alt=""/>
                </div>
                <p>CÔNG TY CỔ PHẦN TẬP ĐOÀN CÔNG NGHỆ OMMANI <br/>MST: 0108160961</p>
                <p className="d-flex align-items-center"><img className="mr-1" src="/assets/image/phone.svg" alt=""/> Hotline: <a className="ml-1" href={`tel:+0916 58 9199`}>0916 58 9199</a></p>
                <p className="d-flex align-items-center"><img className="mr-1" src="/assets/image/email4.svg" alt=""/> Email: <a  className="ml-1 email" href={`mailto:info@ommani.vn`}>info@ommani.vn</a></p>
                <p>Địa chỉ: <span>Số 1, Trần Nguyên Đán,<br/>Phường Định Công, Quận Hoàng Mai, Thành phố Hà Nội</span></p>
              </div>
              <div className="list_box">
                {dataFooter?.linkInfo.length > 0 && dataFooter?.linkInfo.map((val: any, i: number) => {
                  return (
                      <div className="box" key={i}>
                        <h4>{val.title}</h4>
                        <div className="content">
                          {val.items && val.items.map((item: any, index: number) => {
                              return (
                                  <a href='#' key={index}>
                                    {item.name}
                                  </a>
                              )
                          })}
                        </div>
                        {i+1 === dataFooter.linkInfo.length && <div className="mt-4 d-none d-lg-block">
                          <h4>{dataFooter?.title}</h4>
                          <div className="d-flex list_ico align-items-center">
                            <a href="#">
                              <img src="/assets/image/fb.svg" width={34} height={34} alt=""/>
                            </a>
                            <a href="#">
                              <img className='ml-3 mr-3' src="/assets/image/yt.svg" width={48} height={34} alt=""/>
                            </a>
                            <a href="#">
                              <img src="/assets/image/tiktok.svg" width={30} height={34} alt=""/>
                            </a>
                          </div>
                        </div>}
                      </div>
                  )
                })
                }
            </div>
          </div>
          <div className="footer_right w-100 mt-3 d-flex align-items-center justify-content-center">
            <span>© 2023 Ommani. All Rights Reserved</span>
          </div>
        </div>
      </div>
      </div>
  )
}

export default App;
